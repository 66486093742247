import React from 'react'
import PackageList from '../components/Package/PackageList';

const PackagePage = () => {
    return (
        <div>
            <PackageList />
        </div>
    )
}

export default PackagePage;
