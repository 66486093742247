const getBaseUrl = () => {
  // return "https://api.siama.in/";
  return "https://admin.cosmicwedding.in/";
};
export { getBaseUrl };

const getBaseUrl2 = () => {
  // return "https://api.siama.in/";
  return "https://admin.cosmicwedding.in/";
};
export { getBaseUrl2 };
