import React from "react";
import AdminList from "../components/Admin/AdminList";

const Admin = () => {
  return (
    <>
      <AdminList />
    </>
  );
};

export default Admin;
