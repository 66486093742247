import React from "react";
import AddBudgetRange from "../components/BudgetRange/AddBudgetRange";

const AddBudgetRangePage = () => {
  return (
    <div>
      <AddBudgetRange />
    </div>
  );
};

export default AddBudgetRangePage;
