import React from "react";
import BudgetRangeList from "../components/BudgetRange/BudgetRangeList";

const BudgetRange = () => {
  return (
    <div>
      <BudgetRangeList />
    </div>
  );
};

export default BudgetRange;
