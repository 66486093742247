import React from "react";
import CustomerTable from "../components/CustomerTable/CustomerTable";

const CustomerTablePage = () => {
  return (
    <>
      <CustomerTable />
    </>
  );
};

export default CustomerTablePage;
